import React, {useEffect, useState,} from 'react';
import {Button, TextField, useMediaQuery} from '@material-ui/core';
import mzLogo from "../img/mz-logo.png";
import sliderBg1 from "../img/slider-bg-1.jpg";
import sliderBg2 from "../img/slider-bg-2.jpg";
import sliderBg3 from "../img/slider-bg-3.jpg";
import sliderBg4 from "../img/slider-bg-4.jpg";
import heart from '../img/heart.png';
import financialServices from '../img/financialServices.png';
import rizikManagement from '../img/rizikManagement.png';
import testimonial from '../img/testimonial-bg.png';
import about from '../img/about-us.jpg';
import bgrList from '../img/bgrList.jpg'
import animateNumber from '../img/animate-number.png';
import dashbort from "../img/products/dashbort.png";


import mlis from '../img/Mlis.png';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import RoomIcon from '@material-ui/icons/Room';
import PhoneIcon from '@material-ui/icons/Phone';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import SubdirectoryArrowRightIcon from '@material-ui/icons/KeyboardTab';
import FilterTiltShiftIcon from '@material-ui/icons/FilterTiltShift';
import FacebookIcon from '@material-ui/icons/Facebook';
import MenuIcon from '@material-ui/icons/Menu';
import '../App.css';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, {Pagination, Autoplay, Navigation} from 'swiper';
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import {useInView} from "react-intersection-observer";
import CountUp from 'react-countup';
import {useHistory} from "react-router";
import axios from "axios";
import TooltipError from "../components/TooltipError";
import CustomDialog from "../components/dialogs/CustomDialog";
import ResponseFormDialog from "../components/dialogs/ResponseFormDialog";
import Projects from "../components/Projects";
import medis from "../img/products/medis.png";
import useGoogleAnalytics from "../hooks/useGoogleAnalytics";


SwiperCore.use([Pagination, Autoplay, Navigation]);

const useStyles = makeStyles(() => ({
    sliderIndicator: {
        display: 'inline-flex',
        width: 40,
        height: 5,
        backgroundColor: '#898a8b',
        margin: '0 10px',
        borderRadius: 'unset',
        cursor: 'pointer',
        marginBottom: '4.1rem',
    },
    sliderIndicatorActive: {
        display: 'inline-flex',
        width: 40,
        height: 5,
        backgroundColor: '#ffffff',
        margin: '0 10px',
        borderRadius: 'unset',
        cursor: 'pointer',
        marginBottom: '4.1rem',
    },
    wrapperTextField: {
        marginBottom: 20,
        '& p': {
            color: '#fff',
        },
        '& .MuiInputBase-root': {
            color: '#fff',
            paddingLeft: 34,
            '&:before': {
                borderBottom: '1px solid #ffffff',
            },

            '&:after': {
                borderBottom: '2px solid #ffffff',
            },
            '&:hover': {
                '&:before': {
                    borderBottom: '1px solid #ffffff',
                }
            },
        },

        '& .MuiFormControl-root': {
            width: '100%'
        }
    },
    wrapperFormButton: {
        '&:hover': {
            color: '#f26522',
            border: '1px solid  #f26522',
        }
    }
}));

function Home() {
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width: 900px)');
    const [ref, inView, entry] = useInView()
    const [s1, inViewS1, entryS1] = useInView(
        {threshold: 0.65, rootMargin: `${isMobile ? '1px 0px 0px 0px' : '100px 0px 0px 0px'}`})
    const [s2, inViewS2, entryS2] = useInView(
        {threshold: isMobile ? 0.30 : 0.65, rootMargin: `${isMobile ? '1px 0px 0px 0px' : '100px 0px 0px 0px'}`})
    const [s3, inViewS3, entryS3] = useInView({threshold: 0.85, rootMargin: '100px 0px 0px 0px'})
    const [s4, inViewS4, entryS4] = useInView({threshold: 0.65, rootMargin: '100px 0px 0px 0px'})
    const [s5, inViewS5, entryS5] = useInView({threshold: 0.75, rootMargin: '1px 0px 0px 0px'})
    const [s6, inViewS6, entryS6] = useInView()
    const [s7, inViewS7, entryS7] = useInView({threshold: 0.55, rootMargin: '0px 0px 0px 10px'})
    const [s8, inViewS8, entryS8] = useInView({threshold: 0.55, rootMargin: '100px 0px 0px 0px'})
    const [searchShow, setSearchShow] = useState(true)
    const history = useHistory();
    const [openError, setOpenError] = useState(false);
    const [{subject, message,  email, title}, setState] = useState({subject : "", message : "",  email : "", title : ""});


    const conditionsPay = [
        {
            visible: subject.length < 3,
            text: 'Додайте ім\'я'
        },
        {
            visible: email.length < 3 || !email.includes('@')  || !email.includes('.'),
            text: 'Введіть коректну пошту'
        },
        {
            visible: title.length < 3,
            text: 'Додайте тема'
        },
    ]


    const onChange = (field) => e => {setState(prevState => {
        return {...prevState, [field]: e.target.value};
      });
    };



    const sendForm = async (e) => {
        e.preventDefault();
        if (conditionsPay.find(el => el.visible)) {
            return  setOpenError(true)
        }
        setOpenError(false)
        try {
            const res =  await axios.post("/send",{message: message, email: email, subject: subject, title: title})
            if (res.data.status === 'success'){
                CustomDialog(ResponseFormDialog, {})
            }
        } catch (e) {
            CustomDialog(ResponseFormDialog, {error: e.message})
        }
        setState({title: "", email:"", message: "", subject: ""})
    }












    return (
        <div className="root">
            <div>
                <div className='burgerMenu' style={{
                    transform: searchShow ? 'translateX(100%)' : 'translateX(0%)',
                }}>
                    <div className='burgerMenuWrapper'>
                        <div>
                            Меню
                        </div>
                        <div className='closeIconsWrapper' onClick={() => setSearchShow(true)}>
                            <HighlightOffIcon fontSize="default"/>
                        </div>
                    </div>

                    <ul>
                        <ListItem text={'Головна'} refEl={entryS1?.target} inView={inViewS1}/>
                        <ListItem text={'Впровадження'} refEl={entryS2?.target} inView={inViewS2}/>
                        <ListItem text={'Рішення'} refEl={entryS3?.target} inView={inViewS3}/>
                        <ListItem text={'Послуги'} refEl={entryS4?.target} inView={inViewS4}/>
                        <ListItem text={'Про нас'} refEl={entryS5?.target} inView={inViewS5}/>
                        <ListItem text={'Портфоліо'} refEl={entryS6?.target} inView={inViewS6}/>
                        <ListItem text={'Відгуки'} refEl={entryS7?.target} inView={inViewS7}/>
                        <ListItem text={'КОНТАКТИ'} refEl={entryS8?.target} inView={inViewS8}/>
                    </ul>
                </div>
            </div>

            <div className="header">
                <div className="container headerWrapper">
                    <div>
                        <img src={mzLogo} alt="mz logo"/>
                    </div>
                    <ul>
                        <ListItem text={'Головна'} refEl={entryS1?.target} inView={inViewS1}/>
                        <ListItem text={'Впровадження'} refEl={entryS2?.target} inView={inViewS2}/>
                        <ListItem text={'Рішення'} refEl={entryS3?.target} inView={inViewS3}/>
                        <ListItem text={'Послуги'} refEl={entryS4?.target} inView={inViewS4}/>
                        <ListItem text={'Про нас'} refEl={entryS5?.target} inView={inViewS5}/>
                        <ListItem text={'Портфоліо'} refEl={entryS6?.target} inView={inViewS6}/>
                        <ListItem text={'Відгуки'} refEl={entryS7?.target} inView={inViewS7}/>
                        <ListItem text={'КОНТАКТИ'} refEl={entryS8?.target} inView={inViewS8}/>
                    </ul>
                    <div className='burgerWrapper' onClick={() => setSearchShow(!searchShow)}>
                        <MenuIcon fontSize="small"/>
                    </div>

                </div>
            </div>


            <div ref={s1} className="sliderWrapper">
                <Swiper
                    navigation
                    pagination={{
                        clickable: true,
                        bulletClass: classes.sliderIndicator,
                        bulletActiveClass: classes.sliderIndicatorActive
                    }}
                    slidesPerView={1}
                    slidesPerGroup={1}
                    loop={true}

                >
                    <SwiperSlide  className='sliderWrapperItem'>
                        <div className='sliderWrapperItem' style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url(${sliderBg1})`}}>
                            <div className="sliderWrapperContent">
                                <h1 > Автоматизація лабораторій та мед. закладів від А до Я </h1>
                                <p >Перетворення ідей в надійні програмні продукти. Десятки модулів для медицини та бізнесу. Аналітика та СRM </p>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className='sliderWrapperItem'>
                        <div className="sliderWrapperContent"  style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url(${sliderBg2})`}}>
                            <h1> Запровадження стандартів якості обліку біоматеріалу </h1>
                            <p>Пришвидшення обігу матерілу, видачі результату. Контроль на кожному етапі. Конфігурація процесів, валідація.</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='sliderWrapperItem' style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url(${sliderBg4})`}}>
                        <div className="sliderWrapperContent" >
                            <h1> Баклабораторний модуль </h1>
                            <p>Система MZ-LIMS дохволяє опрацьовувати баклабораторні дослідження швидко та якісно.</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='sliderWrapperItem' style={{background: `url(${sliderBg3})`}}>
                        <div className="sliderWrapperContent" >
                            <h1> Лабораторія в хмарі </h1>
                            <p>Система MZ-LIMS працюють на основі хмарних рішень, що дозволяє розгортати ЛІС за лічені дні.
                                Ваші аналізатори скидають дані в хмару. Дані пацієнтів та система в цілому надійна захищена безпековими рішеннями провідних ЦОД європи та України  </p>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className='sliderWrapperButton'>
                <IconButton className='downArrow bounce'
                            onClick={() => entryS2?.target.scrollIntoView({behavior: 'smooth'})}>
                    <ArrowDownwardIcon fontSize="small" style={{color: '#f26522'}}/>
                </IconButton>
            </div>

            <div ref={s2} className='field'>
                <h2>Впровадження</h2>
                <div className='container fieldWrapper'>
                    <div className='fieldItemWrapper'>
                        <img src={heart} alt="heart"/>
                        <h3>Аналіз процесів, Підключення аналізаторів.</h3>
                    </div>
                    <div className='fieldItemWrapper'>
                        <img src={financialServices} alt="financialServices" style={{paddingTop: 5,}}/>
                        <h3>Налаштування бізнес модулів. Налаштування аналітики та роботи з клієнтами </h3>
                    </div>
                    <div className='fieldItemWrapper'>
                        <img src={rizikManagement} alt="rizikManagement"/>
                        <h3>Управління Ризиками. Валідація результатів. Допомога при сертифікації</h3>
                    </div>
                </div>
            </div>


            <div ref={s3} className='solution' style={{background: `url(${testimonial})`}}>
                <div className='container'>
                    <h2>Рішення</h2>
                    <div className='solutionWrapper'>
                        <div>
                            <div className='solutionItemWrapper'>
                                <div>
                                    <ArrowForwardIcon fontSize="small"/>
                                </div>
                                <h3 accessKey='1'>Лабораторна інформаційна система - MZ-LIS</h3>
                            </div>
                            <div className='solutionItemWrapper'>
                                <div>
                                    <ArrowForwardIcon fontSize="small"/>
                                </div>
                                <h3>Медична інформаційна система</h3>
                            </div>
                            <div className='solutionItemWrapper'>
                                <div>
                                    <ArrowForwardIcon fontSize="small"/>
                                </div>
                                <h3>Інформаціна система для радіології та функціональної діагностика</h3>
                            </div>
                            <div className='solutionItemWrapper'>
                                <div>
                                    <ArrowForwardIcon fontSize="small"/>
                                </div>
                                <h3>Оранізація черги та автоматизована видача результатів</h3>
                            </div>
                            <div className='solutionItemWrapper'>
                                <div>
                                    <ArrowForwardIcon fontSize="small"/>
                                </div>
                                <h3>Онлайн магазин кбінети лікаря та пацієнта</h3>
                            </div>


                            <div className='solutionItemWrapper'>
                                <div>
                                    <ArrowForwardIcon fontSize="small"/>
                                </div>
                                <h3>АНАЛІТИКА ДАНИХ та бізнес аналітика</h3>
                            </div>

                        </div>


                    </div>
                </div>
            </div>


            <div ref={s4} className='services container'>
                <h2>ПОСЛУГИ</h2>

                <div className='servicesWrapper'>

                    <div className='servicesWrapperItem'>
                        <div>
                            <AssignmentIcon fontSize="large"/>
                        </div>
                        <div>
                            <h3>Підключення та супровід автоматичних аналізаторів </h3>
                            <p>Підключення білше ніж 250 різних типів аналізаторів до хмари супровід настройка потоків біоматеріалу, аналіз процесів обігу біоматерілалу.</p>
                        </div>
                    </div>

                    <div className='servicesWrapperItem'>
                        <div>
                            <AspectRatioIcon fontSize="large"/>
                        </div>
                        <div>
                            <h3>Впровадження системи MZLis для забору матеріалу - штрихування матеріалу відповідно до стандартів якості.</h3>
                            <p>Для точного виконання дослідження забір матеріалу від клієнта передбачає штрихування його проби та збір анамнезу пацієнта.</p>
                        </div>
                    </div>

                    <div className='servicesWrapperItem'>
                        <div>
                            <ImportantDevicesIcon fontSize="large"/>
                        </div>
                        <div>
                            <h3>Обіг та сортування матеріалу</h3>
                            <p>Організація постів. Оптимізація сортування матеріалу. Протоколювання роботи лабаорантів. Технлологічні карти</p>
                        </div>
                    </div>


                    <div className='servicesWrapperItem'>
                        <div>
                            <ImportantDevicesIcon fontSize="large"/>
                        </div>
                        <div>
                            <h3>Зворотній зв'язок з пацієнтом та лікарем</h3>
                            <p>Кабінет лікаря та пацієнта. Пацієнт одержує результат в найкоротші терміни. Інформування з допомогою чатів. Порівння результатів</p>
                        </div>
                    </div>
                    <div className='servicesWrapperItem'>
                        <div>
                            <FilterTiltShiftIcon fontSize="large"/>
                        </div>
                        <div>
                            <h3>СПЕЦІАЛЬНА ПІДТРИМКА</h3>
                            <p>Клієнтоорієнтована підтримка. Лабораторії забезпечені можливістю практично круглодобової підтримки.
                                Автоматичний монтіоринг проблем дозволяє вчасно спеціалістам з підтримки вирішувати численні
                                потенційні можливості збоїв як в мережах аналізаторів та і хибні забори .</p>
                        </div>
                    </div>
                    <div className='servicesWrapperItem'>
                        <div>
                            <ImportantDevicesIcon fontSize="large"/>
                        </div>
                        <div>
                            <h3>СТАБІЛЬНИЙ СЕРВІС ЯКИЙ ПРАЦЮЄ РОКАМИ</h3>
                            <p>10 років досвідуроботи в галузі дозволяє швидко адаптовуватися під потреби ринку, який швидко розвивається особливо на фоні епідемії COVID-19.
                               Наша ціль запустити вашу лабораторію - ми не орієнтуємся на швидки прибуток а намагаємося залишити клієнта задоволеним
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div ref={s5} style={{background: '#F9F9F9'}}>
                <div className='professionalWrapper'>
                    <div style={{
                        background: `url(${about})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover'
                    }} className='professionalWrapperBackground'
                    >
                    </div>
                    <div className='professionalWrapperContent'>
                        <h3>МИ <br/> ПРОФЕСІОНАЛИ</h3>
                        <p>MZ-Group-це спеціалізована фірма з обслуговування програмного забезпечення, що
                            базується у
                            Львові, Україна. Ми маємо великий досвід роботи в багатьох різних областях розробки
                            програмного
                            забезпечення. Наш досвідчений штат професіоналів працював з широким спектром
                            платформ, мов
                            та
                            інструментів в ході наших різних проектів.</p>
                    </div>
                </div>

                <div>
                    <h2 className='portfolioTitle'>Наші продукти </h2>
                    {isMobile ?
                        <div className='portfolioWrapper'>
                            <Swiper
                                slidesPerView={1.3}
                                slidesPerGroup={1}
                                loop={true}
                                spaceBetween={10}
                            >
                                <SwiperSlide>
                                <div style={{overflow: 'hidden'}} onClick={() => history.push('/lis')}>
                                    <div className='portfolioItems' style={{background: `url(${bgrList})`}}>
                                        <div className='portfolioItemsContainer'>
                                            <div className='portfolioWrapperImg'>
                                                <SubdirectoryArrowRightIcon fontSize="large"/>
                                            </div>
                                            {/*<h3>Ескулаб лабораторія</h3>*/}
                                            <h4>Лабаораторна інформаційна система, MZ-LIS</h4>
                                        </div>
                                    </div>
                                </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                <div style={{overflow: 'hidden'}} onClick={() => history.push('/mis-promis')}>
                                    <div className='portfolioItems'
                                    >
                                        <div style={{
                                            background: `url(${mlis})`,
                                            height: '20rem',
                                            width: '100%',
                                            backgroundSize: 'cover',
                                        }}>
                                            <div className='portfolioItemsContainer'>
                                                <div className='portfolioWrapperImg'>
                                                    <SubdirectoryArrowRightIcon fontSize="large"/>
                                                </div>
                                                <h4> Медично інформаційна система MIS PROMIS</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                <div style={{overflow: 'hidden'}} onClick={() => history.push('/medis')}>
                                    <div className='portfolioItems portfolioItemsMedis' style={{
                                        background: `url(${medis})`
                                    }}>
                                        <div className='portfolioItemsContainer'>
                                            <div className='portfolioWrapperImg'>
                                                <SubdirectoryArrowRightIcon fontSize="large"/>
                                            </div>
                                            <h4> Кабінет пацієнта</h4>
                                        </div>
                                    </div>
                                </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div style={{overflow: 'hidden'}} onClick={() => history.push('/dashboard')}>
                                        <div className='portfolioItems portfolioItemsMedis' style={{
                                            background: `url(${dashbort})`
                                        }}>
                                            <div className='portfolioItemsContainer'>
                                                <div className='portfolioWrapperImg'>
                                                    <SubdirectoryArrowRightIcon fontSize="large"/>
                                                </div>
                                                <h4>Дашборд</h4>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>

                        </div>
                        :
                        <div className='portfolioWrapper'>
                            <div style={{overflow: 'hidden'}} onClick={() => history.push('/lis')}>
                                <div className='portfolioItems' style={{background: `url(${bgrList})`}}>
                                    <div className='portfolioItemsContainer'>
                                        <div className='portfolioWrapperImg'>
                                            <SubdirectoryArrowRightIcon fontSize="large"/>
                                        </div>
                                        {/*<h3>Ескулаб лабораторія</h3>*/}
                                        <h4>Лабаораторна інформаційна система, MZ-LIS</h4>
                                    </div>
                                </div>
                            </div>
                            <div style={{overflow: 'hidden'}} onClick={() => history.push('/mis-promis')}>
                                <div className='portfolioItems'
                                >
                                    <div style={{
                                        background: `url(${mlis})`,
                                        height: '20rem',
                                        width: '100%',
                                        backgroundSize: 'cover',
                                    }}>
                                        <div className='portfolioItemsContainer'>
                                            <div className='portfolioWrapperImg'>
                                                <SubdirectoryArrowRightIcon fontSize="large"/>
                                            </div>
                                            <h4> Медично інформаційна система MIS PROMIS</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{overflow: 'hidden'}} onClick={() => history.push('/medis')}>
                                <div className='portfolioItems portfolioItemsMedis' style={{
                                    background: `url(${medis})`
                                }}>
                                    <div className='portfolioItemsContainer'>
                                        <div className='portfolioWrapperImg'>
                                            <SubdirectoryArrowRightIcon fontSize="large"/>
                                        </div>
                                        <h4> Кабінет пацієнта</h4>
                                    </div>
                                </div>
                            </div>
                            <div style={{overflow: 'hidden'}} onClick={() => history.push('/dashboard')}>
                                <div className='portfolioItems portfolioItemsMedis' style={{
                                    background: `url(${dashbort})`
                                }}>
                                    <div className='portfolioItemsContainer'>
                                        <div className='portfolioWrapperImg'>
                                            <SubdirectoryArrowRightIcon fontSize="large"/>
                                        </div>
                                        <h4>Дашборд</h4>
                                    </div>
                                </div>
                            </div>
                        </div>}
                </div>
            </div>

            {/*<div className='portfolioWrapperButton' ref={s6}>*/}
            {/*    <Button> <ArrowForwardIcon fontSize="default"/> Переглянути усі</Button>*/}
            {/*</div>*/}

            <div ref={ref} className='statisticsWrapper' style={{background: `url(${animateNumber})`}}>
                <div className='statisticsWrapperContainer container'>
                    <div>
                        {inView && <CountUp end={13}/>}
                        <h3>13 років в IT-бізнесі</h3>
                    </div>
                    <div>
                        {inView && <CountUp end={124}/>}
                        <h3>задоволених клієнтів</h3>
                    </div>
                    <div>
                        {inView && <CountUp end={232}/>}
                        <h3>завершених проектів</h3>
                    </div>
                </div>
            </div>

            <div className='teamWrapper' ref={s7}>
                <div className='container'>
                    <h2>Наші проекти</h2>
                </div>
                <Projects />
            </div>


            <div className='wrapperForm' ref={s8} style={{background: `url(${testimonial})`}}>
                <div className='container'>
                    <h3>Написати нам </h3>
                    <div className='wrapperFormAccount'>
                        <div className={classes.wrapperTextField}>
                            <div className='wrapperFormIcons'>
                                <AccountCircleIcon fontSize="default"/>
                            </div>
                            <TextField  value = {subject} onChange={onChange("subject")}/>
                            <p>Імя</p>
                        </div>

                        <div className={classes.wrapperTextField}>
                            <div className='wrapperFormIcons'>
                                <EmailIcon fontSize="default"/>
                            </div>
                            <TextField  value = {email}  onChange={onChange("email")}/>
                            <p>Пошта</p>
                        </div>
                    </div>

                    <div className={classes.wrapperTextField}>
                        <div className='wrapperFormIcons'>
                            <EditIcon fontSize="default"/>
                        </div>
                        <TextField   value = {title}  onChange={onChange("title")}/>
                        <p>Тема</p>
                    </div>

                    <div className={classes.wrapperTextField}>
                        <div className='wrapperFormMesage'>
                            <div className='wrapperFormIcons'>
                                < QuestionAnswerIcon fontSize="default"/>
                            </div>
                            <TextField value = {message} multiline rows={4} onChange={onChange("message")}/>
                            <p>Повідомлення</p>
                        </div>
                    </div>

                    <div className='wrapperFormButtonWrapper'>
                        <TooltipError
                            placement={"bottom"}
                            open={openError}
                            setOpen={setOpenError}
                            value={conditionsPay}
                            component={
                                <Button className={classes.wrapperFormButton} onClick = {sendForm}>
                                    Надіслати
                                </Button>
                            }
                        />

                    </div>
                </div>
            </div>
            <div className='footer'>
                <div className='container'>
                    <div className='footerWrapper'>
                        <div>
                            <div className='footerItemsWrapper'>
                                <div>

                                    <PhoneIcon fontSize="small"/>

                                </div>
                               {<div>
                                    <a href='tel:093'><p>+38(093) 221-05-90 </p></a>

                                </div>}
                            </div>
                            <div className='footerItemsWrapper'>

                                <EmailIcon fontSize="small"/>

                                <div>
                                    <a href='mailto:info@mz-group.com.ua'><p>info@mz-group.com.ua</p></a>
                                </div>
                            </div>

                            <div className='footerItemsWrapper'>
                                <RoomIcon fontSize="small"/>
                                <div>
                                    <a href='mailto:info@mz-group.com.ua'><p>L'viv, Ukraine</p></a>
                                </div>
                            </div>

                        </div>
                        <div className='wrapperMessengersRoot'>
                            <h3 className='wrapperMessengersTitle'>МИ У СОЦІАЛЬНИХ МЕРЕЖАХ</h3>
                            <div className='wrapperMessengers'>
                                <a href="https://www.facebook.com/mzgroup.com.ua">
                                    <FacebookIcon/>
                                </a>
                                {/*<a href="#">
                                    <TwitterIcon/>
                                </a>

                                <a href="#">
                                    <img src={google} style={{height: 19}} alt="google"
                                         className='gooleImgMessengers'/>
                                    <img src={googleColor} style={{height: 19}} alt="googleColor"
                                         className='gooleImgColorMessengers'/>
                                </a>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='footerLanguageWrapper'>
                <div className='container footerLanguageRoot'>
                    <div>
                        <p>COPYRIGHT© 2021 MZ-Group. All Rights Reserved.</p>
                    </div>
                    <div className='languageWrapper'>
                        <span className='languageActive'>UA</span>
                        <span className='languageItem'>EN</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ListItem({text, refEl, inView}) {
    const [ref, setRef] = useState(null)
    useEffect(() => {
        setRef(refEl)
    }, [refEl])
    return (
        <li onClick={() => ref?.scrollIntoView({behavior: 'smooth'})} className={inView ? 'active' : ''}>
            <div>
                {text}
            </div>
        </li>
    )
}

export default Home;


