import React from "react";
import {Card, makeStyles} from "@material-ui/core";
import esculab from "../img/products/esculab.svg";
import biokurs from "../img/products/biokurs.png";
import medis from "../img/products/medis.png";
import dashbort from "../img/products/dashbort.png";
import {useHistory} from "react-router";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridColumnGap: 10,
        gridRowGap: 20,
        paddingLeft: '6.9%',
        paddingRight: '6.9%',
        '& img': {
            width: '100%',
            height: 220,
            objectFit: 'contain',
            '@media (max-width: 900px)': {
                height: 100,
            }
        },
        '@media (max-width: 900px)': {
            gridTemplateColumns: '1fr 1fr',
        }
    },
    cardRoot: {
        padding: '20px 10px',
    },
    title: {
        textAlign: 'center',
        fontSize: '1.25rem',
        fontWeight: 500,
        '@media (max-width: 420px)': {
            minHeight: 54,
        }
    }
}))


const Projects = () => {
    const cl = useStyles()
    return (<div className={cl.root}>
        <CardItem imgUrl={esculab}/>
        <CardItem imgUrl={biokurs}/>
        <CardItem imgUrl={medis}  title={'Кабінет пацієнта'} />
        <CardItem imgUrl={dashbort} title={'Дашборд'} link={'dashboard'}/>
    </div>)
}

export default Projects


const CardItem = ({imgUrl, title, link}) => {
    const cl = useStyles()
    const history = useHistory();

    const handleHistory = () => {
        if(!link){
            return
        }
        history.push(link)
    }

    return (<Card className={cl.cardRoot}>
        {title && <div className={cl.title}>{title}</div>}
        <img onClick={handleHistory} style={{cursor: link ? 'pointer' : ''}} src={imgUrl} alt={imgUrl}/>
    </Card>)
}