import React, {useState} from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import mzLogo from "../img/mz-logo.png";
import MenuIcon from "@material-ui/icons/Menu";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useHistory} from "react-router";
import sliderBg2 from "../img/blog-bg.jpg";
import dashbort from "../img/products/dashbort.png";
import bgrList from "../img/bgrList.jpg";

const useStyles = makeStyles(() => ({
    wrapperVueProject: {
        backgroundColor: '#f26522',
        color: '#ffffff',
        width: 'fit-content',
        padding: '6px 12px',
        borderRadius: '4px 4px 0px 0px',
        marginTop: 5,
        cursor: 'pointer',
        '@media  (max-width: 900px)': {
            marginTop: 20,
        },
    },
    banner: {
        paddingTop: '6.3rem',
        paddingBottom: '6.3rem',
        backgroundRepeat: 'no-repeat !important',
        backgroundSize: 'cover !important',
        backgroundAttachment: 'fixed !important',
        backgroundPosition: '50% 50% !important',
        backgroundColor: '#000 !important',
        color: '#ffffff',
        '& h1': {
            fontSize: '1.13rem'
        }
    },
    container: {
        maxWidth: 1140,
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingLeft: 15,
        paddingRight: 15,
        width: 'FIT-CONTENT',
    },
    imgWrapperRoot: {
        textAlign: 'center',
        paddingTop: 50,
        width: '100%',
    },
    contentWrapper: {
        display: 'grid',
        // gridTemplateColumns: ' 1fr 0.5fr',
        // gridColumnGap: 15,
        // '@media  (max-width: 900px)': {
        //     gridTemplateColumns: '1fr'
        // },
        '& p': {
            color: '#363636',
            margin: 0,
        },
        '& h2': {
            color: '#030303',
            fontSize: '1.9rem',
            marginTop: 10,
            marginBottom: 10,
        }
    },
}))

const Dashboard = () => {
    const cl = useStyles();
    const history = useHistory();
    const [searchShow, setSearchShow] = useState(true)

    return (<div>
        <div>
            <div>
                <div className='burgerMenu' style={{
                    transform: searchShow ? 'translateX(100%)' : 'translateX(0%)',
                }}>
                    <div className='burgerMenuWrapper'>
                        <div>
                            Меню
                        </div>
                        <div className='closeIconsWrapper' onClick={() => setSearchShow(true)}>
                            <HighlightOffIcon fontSize="default"/>
                        </div>
                    </div>

                    <ul>
                        <ListItem text={'Головна'}/>
                        <ListItem text={'Галузі'}/>
                        <ListItem text={'Рішення'}/>
                        <ListItem text={'Послуги'}/>
                        <ListItem text={'Про нас'}/>
                        <ListItem text={'Портфоліо'}/>
                        <ListItem text={'КОНТАКТИ'}/>
                    </ul>
                </div>
            </div>
            <div className="header">
                <div className="container headerWrapper">
                    <div>
                        <a href="/"><img src={mzLogo} alt="mz logo"/></a>
                    </div>
                    <ul>
                        <ListItem text={'Головна'}/>
                        <ListItem text={'Галузі'}/>
                        <ListItem text={'Рішення'}/>
                        <ListItem text={'Послуги'}/>
                        <ListItem text={'Про нас'}/>
                        <ListItem text={'Портфоліо'}/>
                        <ListItem text={'КОНТАКТИ'}/>
                    </ul>
                    <div className='burgerWrapper' onClick={() => setSearchShow(!searchShow)}>
                        <MenuIcon fontSize="small"/>
                    </div>

                </div>
            </div>
        </div>
        <div className={cl.banner} style={{background: `url(${sliderBg2})`}}>
            <div className={cl.container}>
                <h1>Наші проекти</h1>
            </div>
        </div>

        <div className={cl.container}>
            <img className={cl.imgWrapperRoot} src={dashbort} alt="bgrList"/>
            <div className={cl.contentWrapper}>
                <h2>Дашборд </h2>
                <p>Дашборд (або інша назва - "інформаційна панель") - це графічний інструмент або інтерфейс, який надає
                    користувачам зручний спосіб відслідковувати та аналізувати ключову інформацію, дані та метрики, які
                    відображають стан певного процесу, бізнес-аспекту або системи. Головна ідея дашборда полягає в тому,
                    щоб зібрати важливу інформацію на одній сторінці або екрані, роблячи її легкою для відстеження та
                    аналізу.</p>
                <h3>Основні характеристики дашборда повині включати:</h3>
                <ol>
                    <li><p><strong>Графічне відображення даних:</strong> Дашборди часто використовують графіки,
                        діаграми, таблиці та інші візуальні елементи для відображення даних.</p></li>
                    <li><p><strong>Сконцентрованість:</strong> Дашборд має бути лаконічним та зосередженим, відображаючи
                        тільки ключову інформацію.</p></li>
                    <li><p><strong>Інтерактивність:</strong> Користувачі можуть взаємодіяти з дашбордом, вибираючи різні
                        параметри, фільтри і переглядаючи додаткові деталі.</p></li>
                    <li><p><strong>Онлайн оновлення:</strong> Дашборди часто автоматично оновлюються в реальному часі
                        або за регулярним графіком, щоб відображати найактуальніші дані.</p></li>
                    <li><p><strong>Спеціалізація:</strong> Дашборди можуть бути спеціалізовані для різних цілей, таких
                        як маркетинговий аналіз, фінансовий облік, моніторинг виробництва тощо.</p></li>
                    <li><p><strong>Користувацька конфігурація:</strong> Користувачі можуть налаштовувати дашборд згідно
                        зі своїми потребами та вимогами.</p></li>
                    <li><p><strong>Моніторинг ключових показників ефективності:</strong> Дашборди допомагають
                        відслідковувати ключові показники ефективності (KPI) та робити стратегічні рішення на основі цих
                        даних.</p></li>
                </ol>
                <h3>Дашборд містить такі модулі</h3>
                <ol>
                    <li><p><strong>ПММ</strong> - Перегляд і керування учасниками програми лояльності "ПММ".</p></li>
                    <li><p><strong>Звіти по зовнішній службі</strong> - Отримайте звіти та аналітику щодо роботи
                        зовнішніх служб, включаючи лікарів та партнерів.</p></li>
                    <li><p><strong>Звіт по менеджерах (порівняльний)</strong> - Порівняльний аналіз роботи менеджерів за
                        певний період.</p></li>
                    <li><p><strong>Звіт по менеджерах (динамічний)</strong> - Аналіз динаміки роботи менеджерів у часі.
                    </p></li>
                    <li><p><strong>Звіт по лікарях</strong> - Деталізований звіт про роботу лікарів та їх досягнення.
                    </p></li>
                    <li><p><strong>Звіт по напрямкам звернень</strong> - Аналіз напрямків звернень із вказанням
                        додаткової інформації.</p></li>
                    <li><p><strong>Звіт по пакету по лікарях</strong> - Звіт щодо пакетів послуг, наданих лікарям.</p>
                    </li>
                    <li><p><strong>Динаміка чеків</strong> - Статистика та аналітика змін в обсягах чеків.</p></li>
                    <li><p><strong>Активні лікарі</strong> - Перегляд активних лікарів та їх досягнень.</p></li>
                    <li><p><strong>Список лікарів</strong> - Перегляд повного списку лікарів.</p></li>
                    <li><p><strong>Менеджери</strong> - Управління доступами та нарахування бонусів менеджерам.</p></li>
                    <li><p><strong>Плани ММ</strong> - Аналіз виконання планів за різними напрямками.</p></li>
                    <li><p><strong>Робота з пацієнтом</strong> - Управління пацієнтами та зв'язаними процесами.</p></li>
                    <li><p><strong>Список пацієнтів</strong> - Перегляд списку пацієнтів та їх інформації.</p></li>
                    <li><p><strong>Нотифікації</strong> - Сповіщення та повідомлення системи.</p></li>
                    <li><p><strong>Бракування</strong> - Управління бракованими продуктами та послугами.</p></li>
                    <li><p><strong>Пункти забору</strong> - Інформація та статистика по пунктах забору.</p></li>
                    <li><p><strong>Звіти пунктів</strong> - Звіти та аналітика за роботу пунктів.</p></li>
                    <li><p><strong>Бонуси працівника</strong> - Налаштування та нарахування бонусів співробітникам.</p>
                    </li>
                    <li><p><strong>Графіки робіт пунктів</strong> - Розклад роботи пунктів та інформація про графіки.
                    </p></li>
                    <li><p><strong>Додаткові графіки робіт пунктів</strong> - Розширена інформація про графіки роботи
                        пунктів.</p></li>
                    <li><p><strong>Рейтинги ПЗ</strong> - Рейтинги та оцінки продуктів і послуг.</p></li>
                    <li><p><strong>Табель ПЗ</strong> - Табель робочого часу працівників.</p></li>
                    <li><p><strong>Продукти</strong> - Інформація про продукти та послуги компанії.</p></li>
                    <li><p><strong>Послуги</strong> - Управління послугами та їх наданням.</p></li>
                    <li><p><strong>Звіти по продуктах</strong> - Звіти та аналітика щодо продуктів та послуг.</p></li>
                    <li><p><strong>Звіт по пакетах</strong> - Звіти про використання пакетів послуг.</p></li>
                    <li><p><strong>План-факт виконання по продакт-менеджерах</strong> - Оцінка виконання плану
                        продукт-менеджерами.</p></li>
                    <li><p><strong>Виконання досліджень</strong> - Аналіз виконання досліджень та аналізи.</p></li>
                    <li><p><strong>Звіт по пакету по пунктах</strong> - Звіти про використання пакетів послуг по
                        пунктах.</p></li>
                    <li><p><strong>План</strong> - Планування та виконання завдань.</p></li>
                    <li><p><strong>Outsource</strong> - Управління процесом замовлення послуг у зовнішніх партнерів.</p>
                    </li>
                    <li><p>**Дослідження</p></li>
                </ol>
            </div>
        </div>
        <div>
            <div style={{
                display: 'grid',
                gridTemplateColumns: ' 1fr 0.5fr',
            }}>
                <div></div>
                <div className={cl.wrapperVueProject} onClick={() => history.push('/')}>
                    Подивитися проекти
                </div>
            </div>
        </div>
        <div className='footerLanguageWrapper'>
            <div className='container footerLanguageRoot'>
                <div>
                    <p>COPYRIGHT© 2021 MZ-Group. All Rights Reserved.</p>
                </div>
                <div className='languageWrapper'>
                    <span className='languageActive'>UA</span>
                    <span className='languageItem'>EN</span>
                </div>
            </div>
        </div>
    </div>)
}

export default Dashboard

function ListItem({text}) {
    const history = useHistory();
    return (
        <li onClick={() => history.push('/')}>
            <div>
                {text}
            </div>
        </li>
    )
}