import React from 'react';
import {Button, Dialog} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Close} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiDialog-paper': {
            padding: '20px 10px',
        },
    },
    wrapperTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        '& svg': {
            cursor: 'pointer',
            color: '#f26522',
            '&:hover': {
                color: '#000000',
            }
        },
    },
    title: {
        color: '#f26522',
        fontSize: '1.5rem',
    },
    errorText: {
        color: '#FF0000',
        fontSize: '1.5rem',
        fontWeight: 500,
        textAlign: 'center',
        '@media (max-width: 900px)': {
            fontSize: '1.25rem',
        }
    },
    buttonBasket: {
        maxWidth: 245,
        width: '100%',
        height: 45,
        color: '#f26522',
        cursor: 'pointer',
        transition: '0.5s',
        border: '2px solid #f26522',
        margin: '15px auto 0px auto',
        '&:hover': {
            color: '#ffffff',
            backgroundColor: '#f26522',
        }
    }
}))

const ResponseFormDialog = ({handleClose, error}) => {
    const cl = useStyles();
    return (<Dialog
        className={cl.root}
        open={true}
        onClose={handleClose}
        fullWidth>
        {error
            ? <div className={cl.errorText}>Вибачте сталася помилка {error}!</div>
            : <>
                <div className={cl.wrapperTitle}>
                    <div className={cl.title}>Дякуємо за ваше повідомлення!</div>
                    <Close onClick={handleClose}/>
                </div>
                <p>
                    Ми отримали ваше звернення і вдячні вам за зв'язок.
                    <br/>
                    Наша команда незабаром розгляне ваше повідомлення і
                    відповість вам.
                    <br/>
                    Ми завжди готові вам допомогти. <br/> З найкращими побажаннями MZ GROUP
                </p>
            </>}
        <Button className={cl.buttonBasket} onClick={() => handleClose()}>
            добре
        </Button>
    </Dialog>)
}

export default ResponseFormDialog