import { useEffect, useState } from 'react';
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";

const useGoogleAnalytics = () => {
    // const location = useLocation();
    const location = window.location;
    const [initialized, setInitialized] = useState(false);
    const googleId = process.env.GOOGLE_ANALYTICS

    useEffect(() => {
        if (googleId) {
            console.log('1111')
            //ReactGA.initialize(`${googleId}`, { testMode: isDev() });
            ReactGA.initialize(`${googleId}`, );
            setInitialized(true);
        } else if (!googleId) {
            console.warn('Google Analytics not initialized, GoogleId has not been set up properly.');
        }
    }, []);

    useEffect(() => {
        if (initialized) {
            ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search + location.hash });
        }
    }, [initialized, location]);

};

export default useGoogleAnalytics;