import React from 'react';
import {BrowserRouter as Router, Switch, Link, Route} from 'react-router-dom'
import Home from "./page/Home"
import List from "./page/Lis";
import Medis from "./page/Medis";
import Laboratory from "./page/Laboratory";
import Outsourcing from "./page/Outsourcing";
import Mlis from "./page/Mlis";
import Dashboard from "./page/Dashboard";
import useGoogleAnalytics from "./hooks/useGoogleAnalytics";



function App() {
    useGoogleAnalytics()
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Home/>
                </Route>
                <Route path="/lis">
                    <List/>
                </Route>
                <Route path="/medis">
                    <Medis/>
                </Route>
                <Route path="/laboratory">
                    <Laboratory />
                </Route>
                <Route path="/outsourcing">
                    <Outsourcing />
                </Route>

                <Route path="/mis-promis">
                    <Mlis />
                </Route>
                <Route path="/dashboard">
                    <Dashboard />
                </Route>
            </Switch>
        </Router>
    );
}

export default App
