import React from 'react';
import ReactDOM from 'react-dom';
import {MuiThemeProvider} from '@material-ui/core';


export default function CustomDialog(Component, props, event, themeMaterialUI) {
  const modalRoot = document.getElementById('exception-root');
  const lastNode = getLastNode(modalRoot);
  const handleClose = () => {
    ReactDOM.unmountComponentAtNode(lastNode);
  };

  if (event) event.stopPropagation();
  ReactDOM.render(
    (
        <MuiThemeProvider theme={themeMaterialUI}>
        <div>
          <Component handleClose={handleClose} {...props}/>
        </div>
      </MuiThemeProvider>
    ),
    lastNode,
  );
};

function getLastNode(rootNode) {
  if (rootNode.hasChildNodes()) {
    const lastChildOfRootNode = rootNode.lastChild;
    return getLastNode(lastChildOfRootNode);
  } else {
    return rootNode;
  }
}
