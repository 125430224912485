import React, {useEffect, useState} from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import mzLogo from "../img/mz-logo.png";
import MenuIcon from "@material-ui/icons/Menu";
import {useHistory} from "react-router";
import makeStyles from "@material-ui/core/styles/makeStyles";
import sliderBg2 from "../img/blog-bg.jpg";
import medis from "../img/products/medis.png";


const useStyles = makeStyles(() => ({
    banner: {
        paddingTop: '6.3rem',
        paddingBottom: '6.3rem',
        backgroundRepeat: 'no-repeat !important',
        backgroundSize: 'cover !important',
        backgroundAttachment: 'fixed !important',
        backgroundPosition: '50% 50% !important',
        backgroundColor: '#000 !important',
        color: '#ffffff',
        '& h1': {
            fontSize: '1.13rem'
        }
    },
    container: {
        maxWidth: 1140,
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingLeft: 15,
        paddingRight: 15,
        width: 'FIT-CONTENT',
    },
    imgWrapperRoot: {
        textAlign: 'center',
        paddingTop: 50,
        width: '100%',
    },
    contentWrapper: {
        display: 'grid',
        gridTemplateColumns: ' 1fr 0.5fr',
        gridColumnGap: 15,
        '@media  (max-width: 900px)': {
            gridTemplateColumns: '1fr'
        },
        '& p': {
            color: '#363636',
            margin: 0,
        },
        '& h2': {
            color: '#030303',
            fontSize: '1.9rem',
            marginTop: 10,
            marginBottom: 10,
        }
    },
    dateWrapper: {
        '& h3': {
            fontSize: '1rem',
            marginTop: 10,
            marginBottom: 10,
        },
        '& div': {
            borderBottom: '1px solid #eee ',
        },
        '& p': {
            paddingBottom: '15px',
        }
    },

    wrapperVueProject: {
        backgroundColor: '#f26522',
        color: '#ffffff',
        width: 'fit-content',
        padding: '6px 12px',
        borderRadius: '4px 4px 0px 0px',
        marginTop: 5,
        cursor: 'pointer',
        '@media  (max-width: 900px)': {
            marginTop: 20,
        },
    }
}))
const Medis = () => {
    const [searchShow, setSearchShow] = useState(true)
    const classes = useStyles();
    const history = useHistory();
    return (
        <div className='root'>
            <div>
                <div className='burgerMenu' style={{
                    transform: searchShow ? 'translateX(100%)' : 'translateX(0%)',
                }}>
                    <div className='burgerMenuWrapper'>
                        <div>
                            Меню
                        </div>
                        <div className='closeIconsWrapper' onClick={() => setSearchShow(true)}>
                            <HighlightOffIcon fontSize="default"/>
                        </div>
                    </div>

                    <ul>
                        <ListItem text={'Головна'}/>
                        <ListItem text={'Галузі'}/>
                        <ListItem text={'Рішення'}/>
                        <ListItem text={'Послуги'}/>
                        <ListItem text={'Про нас'}/>
                        <ListItem text={'Реалізовані проекти'}/>

                        <ListItem text={'КОНТАКТИ'}/>
                    </ul>
                </div>
            </div>

            <div className="header">
                <div className="container headerWrapper">
                    <div>
                        <a href="/"><img src={mzLogo} alt="mz logo"/></a>
                    </div>
                    <ul>
                        <ListItem text={'Головна'}/>
                        <ListItem text={'Галузі'}/>
                        <ListItem text={'Рішення'}/>
                        <ListItem text={'Послуги'}/>
                        <ListItem text={'Про нас'}/>
                        <ListItem text={'Реалізовані проекти'}/>
                        <ListItem text={'КОНТАКТИ'}/>
                    </ul>
                    <div className='burgerWrapper' onClick={() => setSearchShow(!searchShow)}>
                        <MenuIcon fontSize="small"/>
                    </div>

                </div>
            </div>
            <div className={classes.banner} style={{background: `url(${sliderBg2})`}}>
                <div className={classes.container}>
                    <h1>Наші Роботи</h1>
                </div>
            </div>

            <div className={classes.container}>
                <a href="https://shop.medis.com.ua/"><img className={classes.imgWrapperRoot} src={medis} alt="medis"/></a>
                <div className={classes.contentWrapper}>
                    <div>
                        <h2>Медіс</h2>
                        <p>Медична лабораторія МеДіС - це не просто аналізи. МеДіС - це Ваш вірний та турботливий
                            помічник на шляху до здоров’я, адже за понад 20 років роботи ми ні разу не пішли на
                            компроміс щодо якості аналізів. Засновник лабораторії - Петрух Андрій Васильович - фаховий
                            лікар-лаборант, к.мед.н., який у лабораторії МеДіС втілив заповітну мрію усього свого життя
                            - забезпечити кожного пацієнта якісними лабораторними дослідженнями. Тримаючи високий рівень
                            якості, ми створюємо нові можливості користування послугами МеДіС, щоб Вам було ще зручніше
                            здавати найкращі аналізи.

                            Медична лабораторія МеДіС сертифікована згідно Міжнародного стандарту ISO 9001:2015 та
                            Міжнародного стандарту ISO 15189:2015</p>
                    </div>
                    <div className={classes.dateWrapper}>
                        <div>
                            <h3>Дата</h3>
                            <p>23 квітня 2021</p>
                        </div>
                        <div>
                            <h3>Категорія</h3>
                            <p>Java Development</p>
                        </div>
                    </div>
                </div>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: ' 1fr 0.5fr',
                }}>
                    <div></div>
                    <div className={classes.wrapperVueProject} onClick={() => history.push('/')}>
                        Подивитися проекти
                    </div>
                </div>
            </div>

            <div className='footerLanguageWrapper'>
                <div className='container footerLanguageRoot'>
                    <div>
                        <p>COPYRIGHT© 2021 MZ-Group. All Rights Reserved.</p>
                    </div>
                    <div className='languageWrapper'>
                        <span className='languageActive'>UA</span>
                        <span className='languageItem'>EN</span>
                    </div>
                </div>
            </div>

        </div>

    )
}


function ListItem({text}) {
    const history = useHistory();
    return (
        <li onClick={() => history.push('/')}>
            <div>
                {text}
            </div>
        </li>
    )
}

export default Medis;