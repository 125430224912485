import React, {useEffect, useState} from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import mzLogo from "../img/mz-logo.png";
import MenuIcon from "@material-ui/icons/Menu";
import {useHistory} from "react-router";
import makeStyles from "@material-ui/core/styles/makeStyles";
import sliderBg2 from "../img/blog-bg.jpg";
import ZertRMMobile from '../img/ZertRM-mobile.jpg';


const useStyles = makeStyles(() => ({
    banner: {
        paddingTop: '6.3rem',
        paddingBottom: '6.3rem',
        backgroundRepeat: 'no-repeat !important',
        backgroundSize: 'cover !important',
        backgroundAttachment: 'fixed !important',
        backgroundPosition: '50% 50% !important',
        backgroundColor: '#000 !important',
        color: '#ffffff',
        '& h1': {
            fontSize: '1.13rem'
        }
    },
    container: {
        maxWidth: 1140,
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingLeft: 15,
        paddingRight: 15,
        width: 'FIT-CONTENT',
    },
    imgWrapperRoot: {
        textAlign: 'center',
        paddingTop: 50,
        width: '100%',
        maxHeight: 810,
    },
    contentWrapper: {
        display: 'grid',
        gridTemplateColumns: ' 1fr 0.5fr',
        gridColumnGap: 15,
        '@media  (max-width: 900px)': {
            gridTemplateColumns: '1fr'
        },
        '& p': {
            color: '#363636',
            margin: 0,
        },
        '& h2': {
            color: '#030303',
            fontSize: '1.9rem',
            marginTop: 10,
            marginBottom: 10,
        }
    },
    dateWrapper: {
        '& h3': {
            fontSize: '1rem',
            marginTop: 10,
            marginBottom: 10,
        },
        '& div': {
            borderBottom: '1px solid #eee ',
        },
        '& p': {
            paddingBottom: '15px',
        }
    },

    wrapperVueProject: {
        backgroundColor: '#f26522',
        color: '#ffffff',
        width: 'fit-content',
        padding: '6px 12px',
        borderRadius: '4px 4px 0px 0px',
        marginTop: 5,
        cursor: 'pointer',
        '@media  (max-width: 900px)': {
            marginTop: 20,
        },
    }
}))

const Outsourcing = () => {
    const [searchShow, setSearchShow] = useState(true)
    const classes = useStyles();
    const history = useHistory();
    return (
        <div className='root'>
            <div>
                <div className='burgerMenu' style={{
                    transform: searchShow ? 'translateX(100%)' : 'translateX(0%)',
                }}>
                    <div className='burgerMenuWrapper'>
                        <div>
                            Меню
                        </div>
                        <div className='closeIconsWrapper' onClick={() => setSearchShow(true)}>
                            <HighlightOffIcon fontSize="default"/>
                        </div>
                    </div>

                    <ul>
                        <ListItem text={'Головна'}/>
                        <ListItem text={'Галузі'}/>
                        <ListItem text={'Рішення'}/>
                        <ListItem text={'Послуги'}/>
                        <ListItem text={'Про нас'}/>
                        <ListItem text={'Портфоліо'}/>
                        <ListItem text={'КОНТАКТИ'}/>
                    </ul>
                </div>
            </div>

            <div className="header">
                <div className="container headerWrapper">
                    <div>
                        <a href="/"><img src={mzLogo} alt="mz logo"/></a>
                    </div>
                    <ul>
                        <ListItem text={'Головна'}/>
                        <ListItem text={'Галузі'}/>
                        <ListItem text={'Рішення'}/>
                        <ListItem text={'Послуги'}/>
                        <ListItem text={'Про нас'}/>
                        <ListItem text={'Портфоліо'}/>
                        <ListItem text={'КОНТАКТИ'}/>
                    </ul>
                    <div className='burgerWrapper' onClick={() => setSearchShow(!searchShow)}>
                        <MenuIcon fontSize="small"/>
                    </div>

                </div>
            </div>
            <div className={classes.banner} style={{background: `url(${sliderBg2})`}}>
                <div className={classes.container}>
                    <h1>Наші Роботи</h1>
                </div>
            </div>

            <div className={classes.container}>
                <img className={classes.imgWrapperRoot} src={ZertRMMobile} alt="zertRMMobile"/>
                <div className={classes.contentWrapper}>
                    <div>
                        <h2>Профессиональный IТ-аутсорсинг</h2>
                        <p>Сьогодні компанії роблять необхідні інвестиції та інновації, щоб поділитися серед
                            конкурентів. Вони розуміють, що їх інфраструктура вимагає регулярного обслуговування та
                            нових способів вдосконалення робіт - а також більше з меншими зусиллями.
                            Наші послуги з питань автоматичного управління інфраструктурою допомагають клієнтам
                            скоротити витрати та підвищити ефективність, гнучкість та масштабність. Ми допомагаємо
                            прискорити зростання і отримати більший відгук про захист.
                            Ідея аудиторської діяльності, яка не є основною для їх місій, є привабливим варіантом,
                            оскільки ми пропонуємо модель, яка дозволяє імпортувати зі сложних та неефективних
                            ІТ-засобів. </p>
                    </div>
                    <div className={classes.dateWrapper}>
                        <div>
                            <h3>Дата</h3>
                            <p>23 квітня 2021</p>
                        </div>
                        <div>
                            <h3>Категорія</h3>
                            <p>Java Development</p>
                        </div>
                    </div>
                </div>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: ' 1fr 0.5fr',
                }}>
                    <div></div>
                    <div className={classes.wrapperVueProject} onClick={() => history.push('/')}>
                        Подивитися проекти
                    </div>
                </div>
            </div>

            <div className='footerLanguageWrapper'>
                <div className='container footerLanguageRoot'>
                    <div>
                        <p>COPYRIGHT© 2021 MZ-Group. All Rights Reserved.</p>
                    </div>
                    <div className='languageWrapper'>
                        <span className='languageActive'>UA</span>
                        <span className='languageItem'>EN</span>
                    </div>
                </div>
            </div>

        </div>

    )
}


function ListItem({text}) {
    const history = useHistory();
    return (
        <li onClick={() => history.push('/')}>
            <div>
                {text}
            </div>
        </li>
    )
}

export default Outsourcing;